<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>AI电竞大赛</el-breadcrumb-item>
      <el-breadcrumb-item>机构管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 课程信息筛选 -->
    <el-card style="margin-top: 15px" v-if="this.queryForm.matchType !== '17'">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">条件筛选</span>
        </el-col>
      </el-row>
      <el-form ref="queryFormRef" :model="queryForm" label-width="80px">
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="机构名称" prop="name">
              <el-input
                v-model="queryForm.name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="机构类型" prop="type">
              <el-select v-model="queryForm.type" style="width: 100%" clearable>
                <el-option label="地区" value="area"></el-option>
                <el-option label="集团" value="country"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否授权" prop="accredit">
              <el-select v-model="queryForm.accredit" style="width: 100%" clearable>
                <el-option label="已授权" :value="true"></el-option>
                <el-option label="未授权" :value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="searchFn"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 课程信息列表信息列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="15">
          <span class="title-class title_class">机构信息列表</span>
        </el-col>
        <el-col :span="9" align="right">
          <div class="div_algin_right">
            <el-button type="primary" @click="handleToEdit"
              >新增机构</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="list"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="编号" prop="code"></el-table-column>
        <el-table-column label="机构名称" prop="name"></el-table-column>
        <el-table-column label="所在地区">
          <template slot-scope="scope">
            {{ areaName(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="详细地址" prop="address"></el-table-column>
        <el-table-column label="机构类型" prop="type">
          <template slot-scope="scope">
            <el-tag :type="scope.row.type === 'area' ? 'success' : ''" size="small">{{ scope.row.type === 'area' ? '地区' : '集团' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="负责人" prop="liablePerson"></el-table-column>
        <el-table-column label="机构联系方式" prop="liablePhone"></el-table-column>
        <el-table-column label="备注" prop="note"></el-table-column>
        <el-table-column label="扩展人" prop="expander"></el-table-column>
        <el-table-column label="授权情况" prop="accredit">
          <template slot-scope="scope">
            <el-tag :type="scope.row.accredit ? 'success' : 'warning'" size="small">{{ scope.row.accredit ? '已授权' : '未授权' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-setting"
              size="small"
              @click="handleToAccredit(scope.row)"
              >{{ scope.row.accredit ? '取消授权' : '授权'}}</el-button
            >
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              @click="handleToEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog title="授权" :visible.sync="dialogVisible" width="600px">
      <el-form label-width="140px" :model="form" ref="formRef">
        <el-form-item label="起止时间:" prop="dateRange">
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="changeFn">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注:" prop="note">
          <el-input type="textarea" style="width: 350px;" :rows="4" placeholder="请输入内容" v-model="form.note"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doSaveFn">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="取消授权" :visible.sync="dialogVisible2" width="600px">
      <el-form label-width="140px" :model="form" ref="formRef">
        <el-form-item label="备注:" prop="note">
          <el-input type="textarea" style="width: 350px;" :rows="4" placeholder="请输入内容" v-model="form.note"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="doSaveFn2">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteProductInfo } from '@/http/api'
export default {
  data () {
    return {
      total: 0,
      list: [],
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        accredit: null,
        name: '',
        type: null
      },
      form: {
        accreditBeginTime: '',
        accreditEndTime: '',
        id: '',
        note: ''
      },
      dateRange: [],
      dialogVisible: false,
      dialogVisible2: false
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.$http.get('esportsMatchMechanism/list', { params: this.queryForm }).then(res => {
        if (res.code === 200) {
          this.list = res.data.list
          this.total = res.data.total
        }
      })
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = 20
      this.searchFn()
    },
    handleToEdit (rowData) {
      this.$router.push(
        {
          path: '/AICompetition/toBSetting/edit',
          query: {
            id: rowData.id
          }
        }
      )
    },
    // 删除
    deleteDialog (rowData) {
      this.$confirm('是否将该课程信息删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        // 确认删除
        deleteProductInfo({ id: rowData.id, productStatus: rowData.productStatus }).then((res) => {
          this.$message.success('删除成功！')
          this.getList()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }).catch(() => {
      })
    },
    searchFn () {
      this.queryForm.pageNum = 1
      this.getList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getList()
    },
    areaName (item) {
      return item.province + item.city + item.area
    },
    handleToAccredit (item) {
      if (item.accredit) {
        this.dialogVisible2 = true
      } else {
        this.dialogVisible = true
      }
      this.form.id = item.id
    },
    changeFn (e) {
      if (e.length) {
        this.form.accreditBeginTime = this.$moment(e[0]).format('YYYY-MM-DD HH:mm:ss')
        this.form.accreditEndTime = this.$moment(e[1]).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    doSaveFn () {
      if (!this.form.accreditBeginTime) return this.$message.warning('请选择授权时间')
      this.$http.post('/esportsMatchMechanism/accredit', this.form).then(res => {
        if (res.code === 200) {
          this.$message.success('授权成功')
          this.dialogVisible = false
          this.getList()
        }
      })
    },
    doSaveFn2 () {
      this.$http.post('/esportsMatchMechanism/deleteAccredit', {
        id: this.form.id,
        note: this.form.note
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('已取消授权')
          this.dialogVisible2 = false
          this.getList()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
